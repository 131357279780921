@use 'src/Sass/variables' as v;

.textInput input {
    background-color: v.$l-grey2;
    color: v.$font-2;
    border-radius: 10px;
}

.activeScale {
    background-color: v.$purple;  
    border-color: v.$purple;  
    color: white;
    width: 50px;
    height: 60px;
    border-radius: 10px;
  }

  .inactiveScale{
    background-color: rgba(118, 120, 237, 0.1);
    border-color: rgba(118, 120, 237, 0.1);
    color: v.$purple;  
    width: 50px;
    height: 60px;
    border-radius: 10px;
  }