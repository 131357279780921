@use 'src/Sass/variables' as v;

.sponsorSection {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.sponsorSection p {
  font-size: 20px;
}

.sponsors {
  height: 50px;
  display: flex;
  justify-content: center;
}

.sponsors img {
    max-width: 600px;
    max-height: 60px;
}

 .howItWorks h2,
 .howItWorks h1 {
    color: v.$d-blue2;
    margin: 0 auto;
}

.andMore {
  color: #979797;
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 18px;
  text-align: center;
}

.howItWorks h4 {
  color: v.$d-blue2;
}

.sectionOne {
    padding: 0 100px 55px 100px;
    background-color: #F6E7D7;
}

.sectionOne h1 {
    font-family: 'Formula CondensedBlack', sans-serif;
    line-height: 1.5;
    font-size: 70px;
    margin-bottom: 10px;
}

.sectionOne img {
    width: 100%;
}

.howItWorks h2 {
    font-weight: 600;
    line-height: 1.5;
}

.sectionOne h2.subtitle, .howItWorks h3 {
    color: v.$d-blue2;
    font-size: 24px;
    font-weight: normal;
}

.partner .sectionOne{
    background-color: rgba(3, 205, 170, 0.1);
}

.applicant .sectionOne{
    background-color:  #CFF1FB;
}

.sectionTwo h2 {
  font-family: 'Formula NarrowSemibold', sans-serif;
  font-size: 42px;
}

.sectionTwo h4 {
  font-family: 'Formula SemiCondensedMedium', sans-serif;
}

.sectionOne .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    padding-top: 5em;
}

.sectionOne .grid div:first-of-type{
    margin-top: 50px;
    margin-left: 50px;
}

.sectionTwo .container {
    background-color: white;
    border-radius: 24px;
    margin-top: -5%;
    padding: 50px 0 0px 0;
    width: 85%;
    margin: 0 auto 0 auto;
}

.sectionTwo .grid {
    display: grid;
    margin: 0 auto;
    width: 95%;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.sectionTwo .grid img {
    display: block;
    margin: 0 auto 10px auto;
    width: 70px;
}

.toggleGrid .grid {
    display: grid;
    margin: 0 auto;
    width: 90%;
    grid-template-columns: 50% 50%;
    margin-top: 50px;
    margin-bottom: 50px;
    column-gap: 50px;
}

 .toggleGrid {
    width: 80%;
    margin: 0 auto;
}

.toggleGrid .grid span{
    grid-row: 1;
}

.toggleGrid .grid .stagger{
    grid-column: 2;
}

.toggleGrid .grid .stagger, .toggleGrid .grid .step {
    display:flex;
    align-items: center;
}

.howItWorks .toggleGrid img {
    width: 100% !important;
}

.step p {
    width: 100%;
}

.preFooter {
    padding-bottom: 230px;
}

.partner .gridThreeCol {
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;
    margin: 0 auto;
}
.partner .gridTwoCol {
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    margin: 0 auto;
}
.partner .card {
    background-color: v.$green;
    color: white;
    margin: 0 auto;
    display: block;
}

.partner .gridThreeCol .card {
   width: 300px;
}

.partner .gridTwoCol .card {
    width: 350px;
}

 .partner li, .partner .card p {
    text-align: center !important;
 }

 .welcomeMessage {
   font-size: 50px;
 }

.heroCTA {
  background-color: v.$d-blue2 !important;
  color: #fff !important;
  border: none !important;
  font-family: 'Formula Medium', sans-serif;
}

.heroCTA:hover {
  background-color: #BE2A52 !important;
  color: #fff !important;
  border: none !important;
  font-family: 'Formula Medium', sans-serif;
}

@media only screen and (min-width: 2000px) {
  .sectionOne h1 {
    font-family: 'Formula CondensedBlack', sans-serif;
      line-height: 1.5;
      font-size: 130px;
      margin-bottom: 10px;
      margin-top: 60px
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 850px) {
  .sponsors img {
      max-width: 300px;
      max-height: 30px;
  }

  .heroCTA {
    margin-left: 30%;
    width: 40%;
  }


  .sectionOne {
    padding: 0 0 50px 0;
  }

  .sectionOne img {
    padding: 0 10px 0 10px;
  }

  .sectionOne h1,
  .sectionOne h2.subtitle,
  .howItWorks h3,
  .toggleGrid p,
  .toggleGrid h4 {
      text-align: center;
  }

    .howItWorks button {
        display: block;
        margin: 0 auto;
    }

    .howItWorks .sectionOne {
        padding-bottom: 50px;
    }

    .howItWorks h1, .howItWorks h2 {
        font-size: 28px;
    }

    .sectionOne .grid div:first-of-type{
        margin: 0 auto 5px auto;
        width: 95%;
    }

    .sectionOne button, .toggleGrid .grid img {
        display: block;
        margin: 0 auto;
    }

    .sectionOne a {
        display: flex;
    }


    .sectionOne h1 {
      font-size: 70px;
    }


    .sectionTwo .grid,
    .sectionOne .grid,
    .toggleGrid .grid,
    .partner .gridThreeCol ,
    .partner .gridTwoCol  {
        display: block;
    }

    .sectionTwo .container {
        width: 100%;
        border-radius: 0 ;
        padding: 50px 0 20px 0;
    }

    .toggleGrid {
        width: 95%;
    }

    .toggleGrid .grid .container {
        margin: 0 auto;
        width: 100%;
        position: relative;
        padding: 50px 0px;
    }

    .partner .card {
        display: block;
        margin: 20px auto;
    }

    .partner .gridThreeCol,
    .partner .gridTwoCol  {
        width: 90%;
    }

    .card.pricing {
        border-radius: 0;
    }
}
