@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gravitas+One&display=swap');

/* latin */
@font-face {
  font-family: 'Formula CondensedBlack';
  font-style: normal;
  font-weight: 900;
  src: url(https://cdn.cassy.dev/fonts/PPFormula-CondensedBlack.ttf) format('truetype');
}

/* latin */
@font-face {
  font-family: 'Formula SemiExtendedBold';
  font-style: normal;
  font-weight: 500;
  src: url(https://cdn.cassy.dev/fonts/PPFormula-SemiExtendedBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Formula ExtraBold';
  font-style: normal;
  font-weight: 700;
  src: url(https://cdn.cassy.dev/fonts/PPFormula-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Formula ExtendedBold';
  font-style: normal;
  font-weight: 700;
  src: url(https://cdn.cassy.dev/fonts/PPFormula-ExtendedBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Formula NarrowSemibold';
  font-style: normal;
  font-weight: 700;
  src: url(https://cdn.cassy.dev/fonts/PPFormula-NarrowSemibold.ttf) format('truetype');
}

@font-face {
  font-family: 'Formula NarrowRegular';
  font-style: normal;
  font-weight: 900;
  src: url(https://cdn.cassy.dev/fonts/PPFormula-NarrowRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'Formula SemiCondensedMedium';
  font-style: normal;
  font-weight: 900;
  src: url(https://cdn.cassy.dev/fonts/PPFormula-SemiCondensedMedium.ttf) format('truetype');
}

/* latin */
@font-face {
  font-family: 'Formula Light';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://cdn.cassy.dev/fonts/PPFormula-Light.ttf) format('truetype');
}

/* latin */
@font-face {
  font-family: 'Formula Medium';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://cdn.cassy.dev/fonts/PPFormula-Medium.ttf) format('truetype');
}

html, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(229,229,229,0.2) !important;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: 'Poppins', sans-serif;
}
