@use 'src/Sass/variables' as v;

.about {
  min-height: 80vh; 
}

.about .container {
    width: 90%;
    margin: 0 auto;
    padding-top: 200px;
}

.about .sectionOne {
    padding-bottom: 50px;
    margin-bottom: 30px;
}

.about h1 {
    text-align: center;
    color: white;
    font-size: 64px;
}

.about .sectionTwo h2 {
    color:  v.$d-blue2;
    font-size: 38px;
    font-weight: 600;
    letter-spacing: 0.005em;
    text-align: center;
}

.about h2.banner {
    margin: 70px 0 70px 0;
}

.about h3 {
    color: white;
    font-weight: normal;
    text-align: center;
    margin: 0px auto 30px auto;
}

.about .sectionTwo p {
    font-size: 16px;
}

.about .preFooter {
    padding: 100px 0 230px 0;
}

.about .sectionTwo.container {
    width: 75%;
}

.about .sectionTwo .grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;
    margin: auto;
}

.about .grid p {
    width:100%
}


.about .sectionTwo .grid p:nth-of-type(2) {
    grid-column: 2;
}

.about .sectionTwo img {
    width: 100%;
    display:block;
    margin: -300px auto 50px auto;
    height: 400px;
}

.about .gridThreeCol {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;
    margin: 0 auto;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .about .sectionTwo img {
        height: auto;
        width: 100%;
        display:block;
        margin: -30px auto 20px auto;
    }

    .about .sectionOne {
        padding-bottom: 20px;
    }

    .about h3 {
        font-size: 16px;
    }

    .about .sectionTwo .grid {
        display: block;
    }

    .about .sectionTwo{
        padding-top: 0px;

    }

    .about h1 {
        font-size: 34px;
    }

    .about .sectionTwo h2 {
        font-size: 24px;
    }

    .about h2.banner {
        text-align: center;
    }


    .about .sectionTwo.container {
        width: 85%;
    }

    .about .gridThreeCol {
        display: block;
    }
}
