@use 'src/Sass/variables' as v;

.citations {
  margin: 10px 10px 10px 10px;
  font-size: 12px;
}

 .howItWorks h2,
 .howItWorks h1 {
    color: v.$d-blue2;
    margin: 0 auto;
}

.andMore {
  color: #979797;
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 18px;
  text-align: center;
}

.howItWorks h4 {
  color: v.$d-blue2;
}

.sectionOne {
    padding: 0 60px 65px 60px; // top right bottom left
    background-color: #F6E7D9;
}

.sectionOne h1 {
    max-width: 1000px;
    line-height: 1.5;
    font-size: 66px;
}

.sectionOne img {
    width: 90%;
    margin-top: 12px;
}

.howItWorks h2 {
    font-size: 42px;
    font-weight: 600;
    line-height: 1.5;
}

.sectionOne h2.subtitle, .howItWorks h3 {
    color: v.$d-blue2;
    font-size: 24px;
    font-weight: normal;
}

.partner .sectionOne{
    background-color: rgba(35, 205, 170, 0.1);
}

.sectionOne .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    padding-top: 5em;
}

.sectionOne .grid div:first-of-type{
    margin-top: 50px;
    margin-left: 50px;
}


.sectionTwo h2 {
  font-family: 'Formula NarrowSemibold', sans-serif;
  font-size: 42px;
}

.sectionTwo h4 {
  font-family: 'Formula SemiCondensedMedium', sans-serif;
}

.sectionTwo .container {
    background-color: white;
    border-radius: 24px;
    margin-top: -5%;
    padding: 24px 0 0px 0;
    width: 85%;
    margin: -150px auto 0 auto;
}

.sectionTwo .grid {
    display: grid;
    margin: 0 auto;
    width: 95%;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.sectionTwo .grid img {
    display: block;
    margin: 0 auto 10px auto;
    width: 70px;
}

.toggleGrid .grid {
    display: grid;
    margin: 0 auto;
    width: 90%;
    grid-template-columns: 50% 50%;
    margin-top: 100px;
    margin-bottom: 50px;
    column-gap: 50px;
}

.toggleGrid h1 {
  font-family: 'Formula ExtendedBold', sans-serif;
  font-size: 42px;
}

.hermesHeader {
  font-family: 'Formula CondensedBlack', sans-serif;
  font-size: 42px;
}

.downloadButtonContainer {
  margin: 100px;
}

.toggleGrid h2 {
  font-family: 'Formula ExtendedBold', sans-serif;
  font-size: 42px;
}

.toggleGrid h4 {
  font-family: 'Formula NarrowRegular', sans-serif;
}


.sectionThree {
  margin-bottom: 100px;
  margin-top: 80px;
}

.sectionThree .quotationMark {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 20px;
  margin-bottom: 28px;
}

.sectionThree .quote {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 20px;
}

.sectionThree .testimonialSwitcher {
  display: flex;
  justify-content: center;
}

.testimonialSwitcherButton {
  background-color: #797979;
  border-radius: 5px;
  color: orange;
  margin:  0 10px 0 10px;
  height: 12px;
  width: 50px;
}

.testimonialSwitcherButton:first-child {
  background-color: #BE2A52;
  border-radius: 5px;
  color: orange;
  margin:  0 10px 0 10px;
  height: 12px;
  width: 50px;
}

.sectionFour .container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  background-color: white;
  border-radius: 24px;
  padding: 25px 10px 10px 10px;
  width: 45%;
}

.sectionFour .leftContainer {
  margin-right: 15px;
}

.sectionFour .rightContainer {
  margin-left: 15px;
}

.sectionFour h2 {
  font-family: 'Formula NarrowSemibold', sans-serif;
}

.sectionFour .container h4 {
  font-family: 'Formula NarrowSemibold', sans-serif;
  font-weight: 600;
}

.sectionFour .containerImg {
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
}

.sectionFour .containerMedian {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.containerImg img {
  max-width: 90%;
  max-height: 100%;
  border-radius: 25px;
}

.containerImg video {
  max-width: 90%;
  max-height: 100%;
  border-radius: 25px;
}

.heroVideoMobile {
  display: none;
}

.heroVideoWeb {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.heroVideoWeb video {
  max-width: 90%;
  max-height: 100%;
  border-radius: 25px;
  margin-bottom: 200px;
}

.bottomCTA {
  color: #BE2A52 !important;
  border-color: #BE2A52 !important;
  font-family: 'Formula SemiCondensedMedium', sans-serif;
}

.ctaButton {
  color: #BE2A52 !important;
  border-color: #BE2A52 !important;
  font-family: 'Formula SemiCondensedMedium', sans-serif;
  float: left;
}

.bottomCTA {
  color: #BE2A52 !important;
  border-color: #BE2A52 !important;
  font-family: 'Formula SemiCondensedMedium', sans-serif;
}

.ctaFillButton {
  background-color: v.$d-blue2 !important;
  border-color: v.$d-blue2 !important;
  border: none !important;
  font-family: 'Formula SemiCondensedMedium', sans-serif;
  float: left;
}

.bottomCTA:hover {
  background-color: v.$d-blue2 !important;
  border-color: v.$d-blue2 !important;
  color: #fff !important;
}

.gridContent {
  font-size: 18px;
}

.ctaButton:hover {
  background-color: v.$d-blue2 !important;
  border-color: v.$d-blue2 !important;
  color: #fff !important;
}

.ctaFillButton:hover {
  background-color: #BE2A52 !important;
  color: #F6E7D9 !important;
  color: #fff !important;
}


.keyword {
  color: #BE2A52;
  font-weight: 600;
}

.sectionOne .ctaButton {
  margin-bottom: 200px;
}

.sectionOne .ctaFillButton {
  margin-bottom: 200px;
}

 .toggleGrid {
    width: 80%;
    margin: 0 auto;
}

.toggleGrid .grid span{
    grid-row: 1;
}

.toggleGrid h4 {
  font-size: 38px;
  font-weight: 600;
  width: 80%;
  margin-bottom: 12px;
}

.toggleGrid .grid .stagger{
    grid-column: 2;
}

.toggleGrid .grid .stagger, .toggleGrid .grid .step {
    display:flex;
    align-items: center;
}

.howItWorks .toggleGrid img {
    width: 100% !important;
}

.toggleGrid video {
  width: 100%;
}

.step p {
    width: 100%;
}

.preFooter {
    padding-bottom: 230px;
}

.partner .gridThreeCol {
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;
    margin: 0 auto;
}
.partner .gridTwoCol {
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    margin: 0 auto;
}
.partner .card {
    background-color: v.$green;
    color: white;
    margin: 0 auto;
    display: block;
}

.partner .gridThreeCol .card {
   width: 300px;
}

.testimonialContainer {
  display: flex;
  justify-content: center;
}

.testimonialSubtitle {
  width: 60%;
}

.partner .gridTwoCol .card {
    width: 350px;
}

 .partner li, .partner .card p {
    text-align: center !important;
 }

 .imageWeb {
   display: inline-block;
 }

 .imageMobile {
   display: none;
 }

 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (max-width: 1200px) {
   .heroVideoWeb {
     display: none;
   }

   .heroVideoWeb video {
     display: none;
   }

   .heroVideoMobile {
     display: flex;
     justify-content: space-around;
     flex-direction: column;
     margin-top: -175px;
   }

   .heroVideoMobile img {
     max-width: 100%;
     max-height: 60%;
     border-radius: 25px;
     margin-bottom: 0;
     margin-left: 8%;
   }
 }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 850px) {

  .imageWeb {
    display: none;
  }

  .imageMobile {
    display: inline-block;
  }


  .heroVideoWeb {
    display: none;
  }

  .heroVideoWeb video {
    display: none;
  }

  .heroVideoMobile {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: auto;
  }

  .heroVideoMobile img {
    max-width: 100%;
    max-height: 50%;
    border-radius: 25px;
    margin-left: 0%;
  }

  .testimonialSubtitle {
    width: 80%;
    margin-left: 10%;
  }


  .sectionOne {
      padding: 0 0 0 0; // top right bottom left
      background-color: #F6E7D9;
  }

  .sectionOne img {
    padding: 0 10px 25px 10px;
      width: 100%;
      margin-top: 0;
  }


  .ctaButton {
    float: none;
  }

  .ctaFillButton {
    float: none;
  }

  .sectionOne .ctaButton {
    margin-bottom: 50px;
  }

  .sectionOne .ctaFillButton {
    margin-bottom: 50px;
  }

    .sectionOne h1,
    .sectionOne h2.subtitle,
    .howItWorks h3,
    .toggleGrid p,
    .toggleGrid h4 {
        text-align: center;
    }

    .toggleGrid h4 {
        width: 100%;
    }

    .howItWorks button {
        display: block;
        margin: 0 auto;
    }

    .howItWorks .sectionOne {
        padding-bottom: 150px;
    }

    .howItWorks h1, .howItWorks h2 {
        font-size: 28px;
    }

    .sectionOne .grid div:first-of-type{
        margin: 0 auto 50px auto;
        width: 95%;
    }

    .sectionOne button, .toggleGrid .grid img {
        display: block;
        margin: 0 auto;
    }

    .sectionOne a {
        display: flex;
    }

    .sectionOne h1 {
      font-size: 60px;
    }


    .sectionTwo .grid,
    .sectionOne .grid,
    .toggleGrid .grid,
    .partner .gridThreeCol ,
    .partner .gridTwoCol  {
        display: block;
    }

    .sectionTwo .container {
        width: 100%;
        border-radius: 0 ;
        padding: 50px 0 20px 0;
    }

    .toggleGrid {
        width: 95%;
    }

    .toggleGrid .grid .container {
        margin: 0 auto;
        width: 100%;
        position: relative;
        padding: 50px 0px;
    }

    .testimonialContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .sectionFour .container {
        background-color: white;
        border-radius: 24px;
        padding: 25px 0 0px 0;
        width: 90%;
    }

    .sectionFour .leftContainer {
      margin-right: 0;
      margin-left: 5%;
    }

    .sectionFour .rightContainer {
      margin-left: 5%;
    }

    .sectionFour .containerImg {
      display: flex;
      justify-content: space-around;
    }

    .sectionFour .containerMedian {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
    }

    .partner .card {
        display: block;
        margin: 20px auto;
    }

    .partner .gridThreeCol,
    .partner .gridTwoCol  {
        width: 90%;
    }

    .card.pricing {
        border-radius: 0;
    }
}
