@use 'src/Sass/variables' as v;

.green {
    color: v.$green;     
}

.tile {
    border-radius: 12px;
    padding: 2px 12px;
    display: inline-block;
}

.purple {
    color: v.$purple !important;  
    background-color: rgba(118, 120, 237, 0.1)  !important;  
}

.green {
    color: v.$green;  
    background-color: rgba(41, 182, 151, 0.1);
}

.red {
    color: v.$red !important;  
    background-color: rgba(226, 101, 101, 0.1) !important;
}


.lg {
    padding: 1em 2em;
}

.md {
    padding: 10px 20px;
}

.card .avi {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    object-fit: cover;
}

.card {
    border: 1px solid v.$grey;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
  
.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.connectionButton {
    flex-direction: column;
    width: '5%'; 
    cursor:'pointer';
}

.collegeCard:hover {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.2);
}
.collegeCard {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.1);
}