@use 'src/Sass/variables' as v;

.green {
    background-color: v.$green;
    border-color: v.$green;
}

.orange {
    background-color: v.$orange;
    border-color: v.$orange;
}

.orange:hover {
    background-color: v.$orange;
    border-color: v.$orange;
}

.green:hover{
    background-color: v.$green-hover;
    border-color: v.$green-hover;
}

.filter {
    background-color: white !important;
    color: v.$green !important;
    border-radius: 10px !important;
    display: block !important;
    padding: .375rem .75rem !important;
    font-size: 1rem  !important;
    line-height: 1.5 !important;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-bottom-right-radius: 0 !important;
    border-right: none !important;
    border-top-right-radius: 0 !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.search, .search input {
    border-left: none !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    flex-grow: 1;
}

.subscribeButton {
  background-color: #BE2A52 !important;
  border-color: #BE2A52 !important;
}

.subscribeButton:hover {
  background-color: #323232 !important;
  border-color: #323232 !important;
}

.subscribeButtonText {
  color: #fff !important;
}
