@use 'src/Sass/variables' as v;

.blog .container {
    width: 90%;
    padding-top: 0px !important;
    margin: 100px auto;
}


.blogPost .container {
    width: 90%;
    padding-top: 0px !important;
    margin: 0 auto;
}

.blog .sectionOne {
}

.blog h1, .blogPost h1 {
    text-align: center;
    color: v.$d-blue2;
    font-size: 64px;
}

.blog h2, .blogPost h2 {
    font-size: 38px;
    color: v.$d-blue2;
}

.blogPost.essentials {
    background-color: white;
}

.blogPost.essentials h2 {
    font-size: 25px;
}

.blogPost.essentials .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    row-gap: 50px;
    column-gap: 50px;
}

.blogPost.essentials .grid img{
    max-height: 250px;
    max-width: 100%;
}


.blogPost.essentials .grid:nth-of-type(even) img,
.blogPost.essentials .grid:nth-of-type(odd) span {
    grid-column: 2;
    grid-row: 1;
}

.blogPost.essentials .grid:nth-of-type(odd) img,
.blogPost.essentials .grid:nth-of-type(even) span{
    grid-column: 1;
    grid-row: 1;
}

.blog h3, .blogPost h3 {
    color: v.$body-color;
    font-weight: normal;
    text-align: center;
    margin: 0px auto 30px auto;
}

.blogPost h1, .blogPost h3 {
    text-align: left;
}

.blog .blogTile {
    background-color: white;
    border-radius: 24px;
    width: 80%;
    margin: 0 auto;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.1);
}

.blogTile:hover {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.2);
}


.blog .blogTile .grid{
    display: grid;
    grid-template-columns: 200px auto;
    padding: 30px;
    column-gap: 40px;
}

.blog .preFooter, .blogPost .preFooter {
    padding: 100px 0 300px 0;
}

.blogPost .article {
    width: 80%;
    margin: 0 auto;
}

.blog h2 {
  font-family: 'Formula NarrowSemibold', sans-serif;
}

.blog h4 {
  font-family: 'Formula NarrowRegular', sans-serif;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .blogPost.blogPost.essentials .grid , .blogPost.blogPost.essentials .grid img {
        display: block;
        margin: 0 auto;
    }

    .blog h3, .blogPost h3 {
        font-size: 16px;
    }

    .blog h2, .blogPost h2 {
        font-size: 24px;
    }

    .blog h1, .blogPost h1 {
        font-size: 34px;
    }

    .blogPost .article, .blog .blogTile {
        width: 100%;
    }
    .blog .blogTile .grid img {
        margin-bottom: 20px;
    }
    .blog .blogTile .grid{
        display: block;
    }
}
